import React from 'react';
import './styles/RewardInformation.css'
import { Spring } from 'react-spring/renderprops'

const SCREEN_MAX_WIDTH = 650

class RewardInformation extends React.PureComponent {

    state = {
        bodyTextSize : 0
    }

    constructor(props) {
        super(props)
    
        this.infoContainerRef = React.createRef()
        this.titleContainerRef = React.createRef()

        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions()
    }

    updateDimensions = () => {
        if (this.ismounted) {

          let bodyTxtSize = 0;
          if(this.infoContainerRef != null && 
            this.infoContainerRef.current !== null && 
            this.titleContainerRef != null && 
            this.titleContainerRef.current){
                bodyTxtSize = this.infoContainerRef.current.getBoundingClientRect().height - 
                    this.titleContainerRef.current.getBoundingClientRect().height - 40 - 80;
          }
          
          this.setState({ state: this.state, bodyTextSize : bodyTxtSize })
        }
    }

    componentDidMount() {
        this.ismounted = true
        this.updateDimensions()
    }

    componentWillUnmount(){
        this.ismounted = false
    }

    createMarkup = (string) => {
        return {__html: string}
    }

    render(){
        let informationStyle;
        let informationContainerStyle;
        let informationTextStyle = {};
        let buttonText = this.props.buttonText;

        if (window.innerWidth > SCREEN_MAX_WIDTH) {
            informationStyle = {
                maxHeight : "480px",
                minHeight : "300px",
                height : window.innerHeight * 0.8,
                
                minWidth : "650px", 
                maxWidth : "680px",
                width : window.innerWidth * 0.9,
            }
            informationContainerStyle = { height : this.state.bodyTextSize }
        } else {
            informationStyle = {
                maxHeight : "600px",
                minHeight : "470px",
                height : window.innerHeight * 0.8,
                      
                minWidth : "200px", 
                maxWidth : "450px",
                width : window.innerWidth * 0.9,
            }
            informationContainerStyle = { height : this.state.bodyTextSize }
        }

        if(this.props.configuration !== undefined && this.props.configuration.z_cr !== undefined)
            informationStyle.borderRadius = this.props.configuration.z_cr
        if(this.props.configuration !== undefined && this.props.configuration.d_lc_clr !== undefined)
            informationStyle.background = this.props.configuration.d_lc_clr

        let titleTextStyle = {}
        if (this.props.configuration !== undefined && this.props.configuration.d_lt_clr !== undefined)
            titleTextStyle.color = this.props.configuration.d_lt_clr
        if (this.props.configuration !== undefined && this.props.configuration.d_lt_wght !== undefined)
            titleTextStyle.fontWeight = this.props.configuration.d_lt_wght
        if (this.props.configuration !== undefined && this.props.configuration.d_lt_lgn !== undefined)
            titleTextStyle.textAlign = this.props.configuration.d_lt_lgn

        if (this.props.configuration !== undefined && this.props.configuration.d_ltx_clr !== undefined)
            informationTextStyle.color = this.props.configuration.d_ltx_clr
        if (this.props.configuration !== undefined && this.props.configuration.d_ltx_wght !== undefined)
            informationTextStyle.fontWeight = this.props.configuration.d_ltx_wght
        if (this.props.configuration !== undefined && this.props.configuration.d_ltx_lgn !== undefined)
            informationTextStyle.textAlign = this.props.configuration.d_ltx_lgn

        let actionButtonStyle = {}
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_rds !== undefined)
            actionButtonStyle.borderRadius = this.props.configuration.z_sb_rds 
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_txtClr !== undefined)
            actionButtonStyle.color = this.props.configuration.z_sb_txtClr
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_brdrCrl !== undefined)
            actionButtonStyle.borderColor = this.props.configuration.z_sb_brdrCrl
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_clr !== undefined)
            actionButtonStyle.background = this.props.configuration.z_sb_clr
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_ppr !== undefined)
            if(this.props.configuration.z_sb_ppr === "true"){
                buttonText = buttonText.toUpperCase();
            }

        return (
            <Spring 
                from={{ marginTop: -500 }} 
                to={{ marginTop: 0 }}>
               {props => (<div style={props}>

                    <div className="Information" ref={this.infoContainerRef} style={ informationStyle }>
                        <div className="Information__Container">
                            <div className="Information__Title" ref={this.titleContainerRef} style={titleTextStyle}>{this.props.title}</div>
                            <div className="Information__Text-container" style={informationContainerStyle}>
                                <div className="Information__Text-Body" style={informationTextStyle} dangerouslySetInnerHTML={this.createMarkup(this.props.text)}/>
                            </div>
                            
                        </div>
                        
                        <button onClick={this.props.onBack} className="button__information" style={actionButtonStyle}>{buttonText}</button>
                    </div>

               </div>)}
            </Spring> 
      );
    }
}

export default RewardInformation;