import React,{ createRef } from 'react';
import './styles/Earned.css'
import QRCode from 'qrcode.react'
import { Spring } from 'react-spring/renderprops'

import circleLeft from '../images/circle_left.png'
import circleRight from '../images/circle_right.png'

import * as htmlToImage from "html-to-image";

const SCREEN_MAX_WIDTH = 650

const ONE_HUNDRED_PERCENT   = "100%"
const SEVENTY_FIVE_PERCENT  = "75%"
const SIXTY_FIVE_PERCENT    = "65%"
const FIFTY_PERCENT         = "50%"
const TWENTY_FIVE_PERCENT   = "25%"
const FIFTEEN_PERCENT       = "15%"

const ROW_NAME = "row"
const COLUMN_NAME = "column"

const BADGE_TEXT_REDEEMED = "REDIMIDO"
const BADGE_TEXT_AVAILABLE = "DISPONIBLE"
const FLUX_BALANCE_TEXT = "Valor del cupón"

const createFileName = (extension = "", ...names) => {
  if (!extension) {
    return "";
  }

  return `${names.join("")}.${extension}`;
};

class Earned extends React.PureComponent {

    
  constructor(props) {
    super(props)

    this.state = {
      mShowEarnedReward: false,
      mShowDataEnrichment : false,
      mShowClaimedReward: false,
      disableGenerateRewardButton: true,
    }

    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions()

  }

  ref = createRef(null);

  takeScreenShot = async (node) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };

  download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  downloadScreenshot = () => this.takeScreenShot(this.ref.current).then(this.download);


  updateDimensions = () => {
    if (this.ismounted) {
      this.setState({ state: this.state })
    }
  }

  emailIsValid(email) {
    return /\S+@\S+\.\S+/.test(email)
  }

  onTextChange = (e) => {
    let email = { value: e.target.value }
    this.props.emailCallback(email.value)
    if (this.emailIsValid(email.value)) {
      this.setState({
        disableGenerateRewardButton: false,
      })
    } else {
      this.setState({
        disableGenerateRewardButton: true,
      })
    }
  }

  onNavigateReward = (e) => {
    if (this.props.campaignInfo.linkConfiguration !== null && 
        this.props.campaignInfo.linkConfiguration !== undefined && 
        this.props.campaignInfo.linkConfiguration.link !== "") {
      window.open(this.props.campaignInfo.linkConfiguration.link, "_blank")

      //Mixpanel.track(ConfigValues.MIXPANEL_TAG_FIELD, {"rewardNavigation": this.props.campaignInfo.linkConfiguration.link});

      try {
        try { window.AndroidInterface.onMessage("Go to Site Clicked")} catch(error) { }
        try { window.webkit.messageHandlers.AppleInterface.postMessage("Go to Site Clicked")} catch(error) { }
        try { window.parent.postMessage("Go to Site Clicked", "*")} catch(error) { }
      } catch (error) { }
    }
  }

  onCreateReward = () => {
    this.props.createRewardCallback(this.props.campaignInfo.id)
  }

  setPropsState = () => {
    if (this.props.showEarnedReward) {
      this.setState({        
        mShowEarnedReward: true,
        mShowDataEnrichment : false,
        mShowClaimedReward: false,
      })
    }
    else if (this.props.showDataEnrichment) {
      this.setState({        
        mShowEarnedReward: false,
        mShowDataEnrichment : true,
        mShowClaimedReward: false,
      })
    }
    else if (this.props.showClaimedReward) {
      this.setState({
        mShowEarnedReward: false,
        mShowDataEnrichment : false,
        mShowClaimedReward: true,
      })
    }
  }

  componentDidMount() {
    this.setPropsState()
    this.ismounted = true
  }

  componentWillUnmount(){
    this.ismounted = false
  }

  componentDidUpdate() {
    this.setPropsState()
  }
  
  render() {
    
    let logoImage;
    if(this.props.campaignInfo !== undefined && this.props.campaignInfo.logoImage !== undefined){
      logoImage = this.props.campaignInfo.logoImage
    }
      
    let isVertical;
    let earned;
    let earnedHeaderStyle;
    let earnedHeaderImageStyle;
    let earnedSectionInfoStyle;
    let earnedInformationStyle = {};
    let detailBodyStyle = {};
    let buttonClaimText = this.props.buttonClaim;
    let termsText = this.props.terms;
    let helpText = this.props.help;
    let linkConfigurationText;
    let partnerTextSize = 0;
    let titleFontSize = 0;
    let textMargin = 0;
    let codeFontSize = 0;
    let codeMarginSize = 0;
    let logoSize = 0;
    let isCouponContainerRow = true
    let fluxValueFontSize = 0;
    let fluxAmountFontSize = 0;


    if (window.innerWidth > SCREEN_MAX_WIDTH) {
      isVertical = false;
      earned = {

        maxHeight : "480px",
        minHeight : "400px",
        height : window.innerHeight * 0.8,
        
        minWidth : "650px", 
        maxWidth : "680px",
        width : window.innerWidth * 0.9,

        flexFlow: ROW_NAME
      }

      earnedHeaderImageStyle = { width: ONE_HUNDRED_PERCENT }
      earnedSectionInfoStyle = { height: ONE_HUNDRED_PERCENT, minHeight: earned.minHeight, width: FIFTY_PERCENT }

      if(this.state.mShowEarnedReward) {
        earnedHeaderStyle = { height: ONE_HUNDRED_PERCENT, maxHeight: earned.maxHeight, width: FIFTY_PERCENT }
      } else if (this.state.mShowDataEnrichment || this.state.mShowClaimedReward) {
        earnedHeaderStyle = { height: ONE_HUNDRED_PERCENT, maxHeight: earned.maxHeight, width: FIFTY_PERCENT }
      } 

      //Query Medias
        //Title font Size
        titleFontSize = window.innerWidth * 22 / 680;
        if(titleFontSize >= 22)
          titleFontSize = 22;

        //Partner Text Size
        partnerTextSize = 14;

        //Code font Size
        codeFontSize = window.innerWidth * 32 / 680;
        if(codeFontSize >= 32)
          codeFontSize = 32;

        //Code Margin Size
        codeMarginSize = window.innerHeight * 15 / 480;
        if(codeMarginSize >= 18)
          codeMarginSize = 18;
        else if (codeMarginSize <= 14)
          codeMarginSize = 14;

        //Flux
        fluxValueFontSize = window.innerWidth * 15 / 480;
        if(fluxValueFontSize >= 19)
        fluxValueFontSize = 19;
        else if (fluxValueFontSize <= 10)
        fluxValueFontSize = 10;

        fluxAmountFontSize = window.innerWidth * 40 / 480;
        if(fluxAmountFontSize >= 45)
        fluxAmountFontSize = 45;
        else if (fluxAmountFontSize <= 25)
        fluxAmountFontSize = 25;

        //Size of the Logo
        logoSize = 90;
      //End Query Medias
      
    } else {
      isVertical = true;
      earned = {

        maxHeight : "600px",
        minHeight : "550px",
        height : window.innerHeight * 0.8,
              
        minWidth : "200px", 
        maxWidth : "450px",
        width : window.innerWidth * 0.9,
        
        flexFlow: COLUMN_NAME
      }

      earnedHeaderImageStyle = { width: ONE_HUNDRED_PERCENT }
      earnedSectionInfoStyle = { height: FIFTY_PERCENT, width: ONE_HUNDRED_PERCENT }

      if(this.state.mShowEarnedReward){
        earnedSectionInfoStyle = { height: FIFTY_PERCENT, width: ONE_HUNDRED_PERCENT }
        earnedHeaderStyle = { height: FIFTY_PERCENT, width: ONE_HUNDRED_PERCENT }
      } else if (this.state.mShowDataEnrichment){
        earnedSectionInfoStyle = { height: SIXTY_FIVE_PERCENT, width: ONE_HUNDRED_PERCENT }
      } else if (this.state.mShowClaimedReward){
        earnedSectionInfoStyle = { height: SEVENTY_FIVE_PERCENT, width: ONE_HUNDRED_PERCENT }
      }

      if(this.state.mShowEarnedReward){
        earnedInformationStyle = { minHeight: FIFTY_PERCENT }
      } else if (this.state.mShowDataEnrichment || this.state.mShowClaimedReward) {
        earnedHeaderStyle = { height: TWENTY_FIVE_PERCENT, minHeight : TWENTY_FIVE_PERCENT, maxHeight: TWENTY_FIVE_PERCENT, width: ONE_HUNDRED_PERCENT }
      }

      //Query Medias
        //Title font Size
        titleFontSize = window.innerWidth * 22 / 450;
        if(titleFontSize >= 22)
          titleFontSize = 22;
        else if (titleFontSize <= 12)
          titleFontSize = 12;

        //Partner Text Size
        partnerTextSize = window.innerWidth * 16 / 450;
        if(partnerTextSize >= 16)
          partnerTextSize = 16;
        else if (partnerTextSize <= 10)
          partnerTextSize = 10;

        //Code font Size
        codeFontSize = window.innerWidth * 32 / 450;
        if(codeFontSize >= 32)
          codeFontSize = 32;
        else if (codeFontSize <= 14)
          codeFontSize = 14;

        //Code Margin Size
        codeMarginSize = window.innerHeight * 15 / 600;
        if(codeMarginSize >= 18)
          codeMarginSize = 18;
        else if (codeMarginSize <= 14)
          codeMarginSize = 14;

        //Some texts margin
        textMargin = Math.pow(window.innerHeight * 2 / 600, 3);
        if(textMargin >= 16)
          textMargin = 16;
        else if (textMargin <= 4)
          textMargin = 4;

        //Size of the Logo
        logoSize = window.innerHeight * 90 / 600;
        if(logoSize >= 90)
          logoSize = 90;
        else if (logoSize <= 75)
          logoSize = 75;

        //Flux
        fluxValueFontSize = window.innerWidth * 15 / 600;
        if(fluxValueFontSize >= 19)
        fluxValueFontSize = 19;
        else if (fluxValueFontSize <= 10)
        fluxValueFontSize = 10;

        fluxAmountFontSize = window.innerWidth * 40 / 600;
        if(fluxAmountFontSize >= 45)
          fluxAmountFontSize = 45;
        else if (fluxAmountFontSize <= 25)
          fluxAmountFontSize = 25;


      //End Query Medias
    }

    let itemAlign = (align) => {
      if (isVertical) {
        switch (align) {
          case "right":
            return { right: TWENTY_FIVE_PERCENT }
          case "left":
            return { right: SEVENTY_FIVE_PERCENT }
          case "center":
            return { right: FIFTY_PERCENT }
          default:
            return { right: TWENTY_FIVE_PERCENT }
        }
      } else {
        switch (align) {
          case "right":
            return { right: FIFTY_PERCENT, top : FIFTEEN_PERCENT }
          case "left":
            return { right: FIFTY_PERCENT, top : SEVENTY_FIVE_PERCENT }
          case "center":
            return { right: FIFTY_PERCENT, top : FIFTY_PERCENT }
          default:
            return { right: FIFTY_PERCENT, top : FIFTEEN_PERCENT }
        }
      }
    }

    // Dynamic Styles
    if (this.props.configuration !== undefined && this.props.configuration.a_cr !== undefined){
      earnedSectionInfoStyle.background = this.props.configuration.a_cr;
      earnedInformationStyle.background = this.props.configuration.a_cr;
    }

    let advertiserLogoAlign = {};
    if (this.props.configuration !== undefined && this.props.configuration.a_al_lgn !== undefined) {
      advertiserLogoAlign = itemAlign(this.props.configuration.a_al_lgn)
    }
    advertiserLogoAlign.width = `${logoSize}px`
    advertiserLogoAlign.height = `${logoSize}px`

    let advertiserLogoAlignEmpty = {};
    let advertiserLogoAlignState;
    if (this.props.configuration !== undefined) {
      advertiserLogoAlignState = advertiserLogoAlign
    } else {
      advertiserLogoAlignState = advertiserLogoAlignEmpty
    }

    let partnerTitleAlign = {}
    if (this.props.configuration !== undefined && this.props.configuration.a_pt_lgn !== undefined)
      partnerTitleAlign.textAlign = this.props.configuration.a_pt_lgn
    partnerTitleAlign.fontSize = `${partnerTextSize}px`
    partnerTitleAlign.marginBottom = `${textMargin}px`
    let partnerTitleStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.a_pt_clr !== undefined)
      partnerTitleStyle.color = this.props.configuration.a_pt_clr
    if(this.props.configuration !== undefined && this.props.configuration.a_pt_wght !== undefined)
      partnerTitleStyle.fontWeight = this.props.configuration.a_pt_wght

    let partnerRewardStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.a_pr_clr !== undefined)
      partnerRewardStyle.color = this.props.configuration.a_pr_clr
    if (this.props.configuration !== undefined && this.props.configuration.a_pr_lgn !== undefined)
      partnerRewardStyle.textAlign = this.props.configuration.a_pr_lgn
    if(this.props.configuration !== undefined && this.props.configuration.a_pr_wght !== undefined)
      partnerRewardStyle.fontWeight = this.props.configuration.a_pr_wght
    partnerRewardStyle.fontSize = `${titleFontSize}px`
    partnerRewardStyle.marginBottom = `${textMargin}px`


    let partnerCauseStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.a_pt_wght !== undefined)
      partnerCauseStyle.fontWeight =  this.props.configuration.a_pt_wght
    if (this.props.configuration !== undefined && this.props.configuration.a_pt_clr !== undefined)
      partnerCauseStyle.color = this.props.configuration.a_pt_clr
    if (this.props.configuration !== undefined && this.props.configuration.a_pt_lgn !== undefined)
      partnerCauseStyle.textAlign = this.props.configuration.a_pt_lgn

    let partnerValidStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.a_rv_lgn !== undefined)
      partnerValidStyle.textAlign = this.props.configuration.a_rv_lgn
    if (this.props.configuration !== undefined && this.props.configuration.a_rv_clr !== undefined)
      partnerValidStyle.color = this.props.configuration.a_rv_clr
    if (this.props.configuration !== undefined && this.props.configuration.a_rv_wght !== undefined)
      partnerValidStyle.fontWeight =  this.props.configuration.a_rv_wght

    let actionInstructionStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.actionInstruction_align !== undefined)
      actionInstructionStyle = { textAlign: this.props.configuration.actionInstruction_align }
    if (this.props.configuration !== undefined && this.props.configuration.actionInstruction_color !== undefined)
      actionInstructionStyle.color = this.props.configuration.actionInstruction_color

    let actionButtonStyle = {}
    let saveButtonStyle = {}

    let actionButtonStyleDisable = {}
    if (this.props.configuration !== undefined && this.props.configuration.z_ab_rds !== undefined) {
      actionButtonStyle.borderRadius = this.props.configuration.z_ab_rds
      actionButtonStyleDisable.borderRadius = this.props.configuration.z_ab_rds 
      saveButtonStyle.borderRadius = this.props.configuration.z_ab_rds
    }
    if (this.props.configuration !== undefined && this.props.configuration.z_ab_clr !== undefined) {
      actionButtonStyle.background = this.props.configuration.z_ab_clr
      actionButtonStyleDisable.background = this.props.configuration.z_ab_clr
      saveButtonStyle.background = this.props.configuration.z_ab_clr
    }
    if (this.props.configuration !== undefined && this.props.configuration.z_ab_txtClr !== undefined) {
      actionButtonStyle.color = this.props.configuration.z_ab_txtClr
      actionButtonStyleDisable.color = this.props.configuration.z_ab_txtClr
      saveButtonStyle.color = this.props.configuration.z_ab_txtClr
    }
    if (this.props.configuration !== undefined && this.props.configuration.z_ab_txtWght !== undefined) {
      actionButtonStyle.fontWeight = this.props.configuration.z_ab_txtWght
      actionButtonStyleDisable.fontWeight = this.props.configuration.z_ab_txtWght
      saveButtonStyle.fontWeight = this.props.configuration.z_ab_txtWght
    }
    if (this.props.configuration !== undefined && this.props.configuration.z_ab_ppr !== undefined) {

      if(this.props.buttonClaim !== undefined && this.props.buttonClaim !== null)
        if(buttonClaimText !== null && buttonClaimText !== undefined)
          buttonClaimText = buttonClaimText.toUpperCase()

      if (this.props.campaignInfo.linkConfiguration !== null &&
          this.props.campaignInfo.linkConfiguration !== undefined &&  
          this.props.campaignInfo.linkConfiguration.text !== null &&
          this.props.campaignInfo.linkConfiguration.text !== undefined)
        linkConfigurationText = this.props.campaignInfo.linkConfiguration.text.toUpperCase()
    }

    let inputFormStyle = {}
    let inputFormStyleCorrect = {}
    if (this.props.configuration !== undefined && this.props.configuration.actionButton_radius !== undefined) {
      inputFormStyle = { borderRadius: this.props.configuration.actionButton_radius }
      inputFormStyleCorrect = { borderRadius: this.props.configuration.actionButton_radius }
    }
    if (this.props.configuration !== undefined && this.props.configuration.inputForm_border !== undefined) {
      inputFormStyle.borderWidth = this.props.configuration.inputForm_border
      inputFormStyleCorrect.borderWidth = this.props.configuration.inputForm_border
    }
    if (this.props.configuration !== undefined && this.props.configuration.inputForm_color !== undefined)
      inputFormStyle.borderColor = this.props.configuration.inputForm_color

    let rewardTextStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.c_ac_lgn !== undefined)
      rewardTextStyle.textAlign = this.props.configuration.c_ac_lgn 
    if (this.props.configuration !== undefined && this.props.configuration.c_ac_clr !== undefined)
      rewardTextStyle.color = this.props.configuration.c_ac_clr
    if(this.props.configuration !== undefined && this.props.configuration.c_ac_wght !== undefined)
      rewardTextStyle.fontWeight = this.props.configuration.c_ac_wght
    rewardTextStyle.fontSize = `${codeFontSize}px`
    rewardTextStyle.marginTop = `${codeMarginSize}px`
    rewardTextStyle.marginBottom = `${codeMarginSize}px`

    let linksStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.a_le_clr !== undefined){
      linksStyle.color = this.props.configuration.a_le_clr
    }
    if (this.props.configuration !== undefined && this.props.configuration.a_le_wght !== undefined){
      linksStyle.fontWeight= this.props.configuration.a_le_wght
    }
    if (this.props.configuration !== undefined && this.props.configuration.a_le_ppr !== undefined){
      if(termsText !== null && termsText!== undefined){
        termsText = termsText.toUpperCase();
      }
      if(helpText!== null && helpText !== undefined){
        helpText = helpText.toUpperCase();
      }
    }
    let detailsTextStyle = {}
    if (this.props.configuration !== undefined && this.props.configuration.c_dt_clr !== undefined){
      detailBodyStyle.color = this.props.configuration.c_dt_clr
      detailsTextStyle.color = this.props.configuration.c_dt_clr
      detailBodyStyle.textAlign = "center";

    }
    if (this.props.configuration !== undefined && this.props.configuration.c_dt_wght !== undefined){
      detailsTextStyle.fontWeight= this.props.configuration.c_dt_wght
    }

    let fluxValueStyle = {}
    fluxValueStyle.fontSize = `${fluxValueFontSize}px`

    let fluxAmountStyle = {}
    fluxAmountStyle.fontSize = `${fluxAmountFontSize}px`

    //End Dynamic Styles

    //Top Layout, Banner and Top Buttons
    let logoContainerEarned =
      <div className="Circle__image-container" style={{...advertiserLogoAlignState, backgroundImage:`url(${logoImage})`}}></div>
    let logoContainerEnrichment =
      <div className="Circle__image-container-moved" style={{...advertiserLogoAlignState, backgroundImage:`url(${logoImage})`}}></div>
    let logoContainerClaimed =
      <div className="Circle__image-container-generated" style={{...advertiserLogoAlignState, backgroundImage:`url(${logoImage})`}}></div>

    let logoContainerState;
    if(this.state.mShowEarnedReward)
      logoContainerState = logoContainerEarned
    if (this.state.mShowDataEnrichment)
      logoContainerState = logoContainerEnrichment
    else if (this.state.mShowClaimedReward)
      logoContainerState = logoContainerClaimed

    let earnedHeaderImage = <img className="Earned__header-image" style={earnedHeaderImageStyle} src={this.props.campaignInfo.headerImage} alt="Header" />

    let earnedHeaderEarnedContainer =
      <div className="Earned__header" style={earnedHeaderStyle} >
        {earnedHeaderImage}
      </div>
    let earnedHeaderEnrichmentContainer =
      <div className="Earned__header-moved" style={earnedHeaderStyle}>
        {earnedHeaderImage} 
      </div>
    let earnedHeaderClaimedContainer =
      <div className="Earned__header-generated" style={earnedHeaderStyle}>
        {earnedHeaderImage}
      </div>

    let earnedHeaderStateContainer;
    if (this.state.mShowEarnedReward === true)
      earnedHeaderStateContainer = earnedHeaderEarnedContainer
    else if (this.state.mShowDataEnrichment === true)
      earnedHeaderStateContainer = earnedHeaderEnrichmentContainer
    else if (this.state.mShowClaimedReward === true)
      earnedHeaderStateContainer = earnedHeaderClaimedContainer

    let closeImage = <img className="Leave__Image" src={this.props.leaveImage} onClick={this.props.leaveCallback} alt="leaveImg" />
    let closeImageEmpty = <div></div>
    let closeImageState;
    if (this.props.partnerConfig !== undefined)
      if (this.props.partnerConfig.buttonClose !== null && this.props.partnerConfig.buttonClose && this.props.partnerConfig.buttonClose.web !== null && this.props.partnerConfig.buttonClose.web !== undefined){
        if (this.props.partnerConfig.buttonClose.web.enable === "true")
          closeImageState = closeImage;
        else if (this.props.partnerConfig.buttonClose.web.enable === "false")
          closeImageState = closeImageEmpty;
        else
          closeImageState = closeImageEmpty;
      } else{
        closeImageState = closeImageEmpty;
      }

    if(this.props.isDetail === true)
      closeImageState = closeImageEmpty;

    if(this.state.showEarnedReward === true)
      closeImageState = closeImageEmpty;

    let allowResend =
      <div className="Share__Container" onClick={this.props.shareCallback}>
        <img className="Share__Image" src={this.props.forwardImage} alt="shareImg" />
        <div className="Share__Text">{this.props.shareText}</div>
      </div>
    let allowResendEmpty = <div></div>
    let allowResendState;
    if (this.props.partnerConfig !== undefined)
      if (this.props.partnerConfig.allowResend !== undefined)
        if (this.props.partnerConfig.allowResend === "true")
          allowResendState = allowResend
        else if (this.props.partnerConfig.allowResend === "false")
          allowResendState = allowResendEmpty
        else
          allowResendState = allowResendEmpty
      else
        allowResendState = allowResendEmpty

    let leftOptionsState = 
    <div className="Left__Menu">
      { allowResendState }
    </div>

    let topActionsState =
      <div>
        {leftOptionsState}
        {closeImageState}
      </div>
    //End top Layout, Banner and Top Buttons

    // Action Buttons States
    let buttonBuilderState;
    if (this.state.disableGenerateRewardButton) {
      buttonBuilderState = "button__builder-disabled"
      actionButtonStyleDisable.background = "rgb(200, 200, 200)"
    }
    else {
      buttonBuilderState = "button__builder"
      actionButtonStyleDisable.background = this.props.configuration.z_ab_clr
    }

    let earnedRewardButton = <button onClick={this.onCreateReward} className="button__builder" style={actionButtonStyle}>{buttonClaimText}</button>
    let dataEnrichmentButton = <button onClick={this.onCreateReward} disabled={this.state.disableGenerateRewardButton} className={buttonBuilderState} style={actionButtonStyleDisable}>{this.props.buttonGenerate}</button>
    let claimedRewardButtonEmpty = <div></div>
    let claimedRewardButtonState;
    if (this.props.campaignInfo.linkConfiguration !== null && 
        this.props.campaignInfo.linkConfiguration !== undefined && 
        ( this.props.campaignInfo.linkConfiguration.link !== null && this.props.campaignInfo.linkConfiguration.link !== "") ) {
      claimedRewardButtonState = <div><button onClick={this.downloadScreenshot} className="button__builder_l" style={saveButtonStyle}>Guardar &#11015;</button><button onClick={this.onNavigateReward} disabled={false} className="button__builder_r" style={actionButtonStyle}>{linkConfigurationText}</button></div>

    } else {
      claimedRewardButtonState = claimedRewardButtonEmpty
    }

    let stateButton;
    if (this.state.mShowEarnedReward === true)
      stateButton = earnedRewardButton
    else if (this.state.mShowDataEnrichment === true)
      stateButton = dataEnrichmentButton
    else if(this.state.mShowClaimedReward)
      stateButton = claimedRewardButtonState
    //End Action Buttons States

    //Data Enrichment Input
    let emailInstructions = <div className="Email-Instructions" style={actionInstructionStyle}>{this.props.emailInstructions}</div>
    let emailInstructionsState;
    if (this.state.mShowDataEnrichment)
      emailInstructionsState = emailInstructions
    else
      emailInstructionsState = <></>      

    let inputEmailNormal = <input className="input__email" onChange={this.onTextChange} placeholder={this.props.inputPlaceholder} type="text" style={inputFormStyle}></input>
    let inputEmailCorrect = <input className="input__email-correct" onChange={this.onTextChange} placeholder={this.props.inputPlaceholder} type="text" style={inputFormStyleCorrect}></input>
    let correctEmailImg = <img className="correct-mail" src={this.props.correctMailImg} alt="doneMailImage"></img>
    let correctEmailEmpty = <></>
    let correctEmailState;
    let inputEmailState;
    if (this.state.mShowEarnedReward === true)
      inputEmailState = <></>
    else if (this.state.mShowDataEnrichment === true)
      if (this.state.disableGenerateRewardButton) {
        correctEmailState = correctEmailEmpty
        inputEmailState = inputEmailNormal
      }
      else {
        correctEmailState = correctEmailImg
        inputEmailState = inputEmailCorrect
      }
    else
      inputEmailState = <></>
    //End Data Enrichment Input

    //Title, Description, Reason, Validity
    let titleDescReasonValidity =
      <>
        <div className="Earned__partner" style={partnerTitleAlign}> 
          <b style={partnerTitleStyle}>{this.props.campaignInfo.partnerTitle}</b> 
          <div style={partnerTitleStyle}>{this.props.rewardGuider}</div>
        </div>
        <div className="Earned__title" style={partnerRewardStyle}>{this.props.campaignInfo.description}</div>
        <div className="Earned__reason" style={partnerCauseStyle}>{this.props.campaignInfo.reason}</div>
        <div className="Earned__validity" style={partnerValidStyle}>{this.props.campaignInfo.vigenciaTxt}</div>
      </>
    //End Title, Description, Reason, Validity
    
       /*var instructionsTrimmed = this.props.campaignInfo.instructions;
    if (this.props.campaignInfo.instructions.length > 100)
    {
      
      var pos = 101;
      var charstr =  "";
      while(charstr != " " && pos < this.props.campaignInfo.instructions.length){
        pos ++;
        charstr = this.props.campaignInfo.instructions[pos];
      }
      console.log("pos:" + pos);
      instructionsTrimmed  = this.props.campaignInfo.instructions.substring(0, pos);
    }*/

    //Instructions
    let instructionsEmpty = <></>
    let instructionsClaimed =
      <div className="Earned__instructions-body" style={detailBodyStyle}>
        <span className="Earned__instructions-title" style={detailsTextStyle}>DA CLIC PARA VER LAS INSTRUCCIONES DE CANJE</span>
      </div>

    let instructionsState;
    if (this.state.mShowClaimedReward === true)
      instructionsState = instructionsClaimed
    else
      instructionsState = instructionsEmpty
    //End Instructions

    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    //Codes Section
    let codeNumberState;
    if (this.state.mShowClaimedReward && this.props.campaignInfo !== undefined) {
      if (this.props.campaignInfo.codeType !== undefined && this.props.campaignInfo.codeBonnus !== undefined) {
        switch (this.props.campaignInfo.codeType) {
          case 'qr':
            let codeImageStyle = <img className="Earned__coupon-image" src={this.props.campaignInfo.codeImageUrl} alt="..."></img>
            codeNumberState = codeImageStyle
            break;
          case 'qrtext':
            let codeQRTextStyle = <QRCode className="Earned__coupon-image" value={this.props.campaignInfo.codeBonnus}></QRCode>
            codeNumberState = codeQRTextStyle
            break;
          case 'bar':
            let codeImageBarStyle = <img className="Earned__coupon-barCode" src={this.props.campaignInfo.codeImageUrl} alt="..."></img>
            codeNumberState = codeImageBarStyle
            break;
          case 'empty':
            let codeImageEmptyStyle = <></>
            codeNumberState = codeImageEmptyStyle
            break;
          case 'flux':
            let codeQRFluxStyle = <QRCode className="Earned__coupon-image" value={this.props.campaignInfo.codeBonnus}></QRCode>
            codeNumberState = <>
                {codeQRFluxStyle}
                <div className="Earned__coupon-flux-balance-container">
                  <div className="Earned__coupon-flux-text" style={fluxValueStyle}>{FLUX_BALANCE_TEXT}</div>
                  <div className="Earned__coupon-flux-balance" style={fluxAmountStyle}>{`${formatter.format(this.props.campaignInfo.amount/100)}`}</div>
                </div>
              </>
            break;
          case 'gestoPago':
            isCouponContainerRow = false
            let codeNumberGiftCards = this.props.campaignInfo.codeBonnus;
            let folioGiftCards = this.props.campaignInfo.data;
            let codeNumberStyleGiftCards = 
              <>
                <div className="Earned__coupon-number" style={rewardTextStyle}>{codeNumberGiftCards}</div>
                <div className="Earned__coupon-folio" style={partnerCauseStyle}>{folioGiftCards}</div>
              </>
            codeNumberState = codeNumberStyleGiftCards
          break;
          case 'text':
          default:
            let codeNumber = this.props.campaignInfo.codeBonnus;
            
            let STRING_LENGTH_LIMIT = 18;
            if(codeNumber.length > STRING_LENGTH_LIMIT){
              rewardTextStyle.fontSize = `${parseInt(parseFloat(rewardTextStyle.fontSize, 10) * 0.6)}px`;
            }
            let codeNumberStyle = <div className="Earned__coupon-number" style={rewardTextStyle}>{codeNumber}</div>
            codeNumberState = codeNumberStyle
            break;
        }
      }
    }
    //End Codes Section

    //Coupon Container
    let couponContainer;
    let rowContainer = 
      <Spring from={{ opacity: "0.2" }} to={{ opacity: "1.0" }}>
      {props => (<>
        <div className="Earned__information-coupon-container-row" style={props}>
          {codeNumberState}
        </div>
      </>)}
    </Spring>
    

    let columnContainer = 
      <Spring from={{ opacity: "0.2" }} to={{ opacity: "1.0" }}>
      {props => (<>
        <div className="Earned__information-coupon-container-column" style={props}>
          {codeNumberState}
        </div>
      </>)}
    </Spring>

    if(isCouponContainerRow === true)
      couponContainer = rowContainer
    else
      couponContainer = columnContainer
    //End Coupon Container

    //Reward code and Lines
    let couponNumber = <></>
    if (this.state.mShowClaimedReward) {
      couponNumber = 
      <>
        {/*Top Line*/}
        <Spring from={{margin:"0px"}} to={{margin:"0px"}}>
          {props => (<>
            <div className="Earned__line-top" style = {props}>
              <img className="Circle__ticket-left" src={circleLeft} alt=""></img>
              <div className="Earned__line-container-top"></div>
              <img className="Circle__ticket-right" src={circleRight} alt=""></img>
            </div>
          </>)}
        </Spring>

        {/*Reward Code*/}
        { couponContainer }

        {/*Bottom Line*/}
        <Spring from={{margin:"0px"}} to={{margin:"0px"}}>
          {props => (<>
            <div className="Earned__line-bottom" style = {props}>
              <img className="Circle__ticket-left" src={circleLeft} alt=""></img>
              <div className="Earned__line-container-bottom"></div>
              <img className="Circle__ticket-right" src={circleRight} alt=""></img>
            </div>
          </>)}
        </Spring>
      </>
    }

    //Hide Lines if coupon is Empty
    if (this.state.mShowClaimedReward && this.props.campaignInfo !== undefined) {
      if (this.props.campaignInfo.codeType !== undefined && this.props.campaignInfo.codeBonnus !== undefined) {
        if(this.props.campaignInfo.codeType === 'empty'){
          couponNumber = <div className="Earned__instructions-nocode"></div>;
        }
      }
    }
    
      
    //mShowClaimedReward state
    let couponNumberEmpty = <></>
    let couponNumberState;
    if (this.state.mShowClaimedReward)
      couponNumberState = couponNumber
    else
      couponNumberState = couponNumberEmpty

    let textContainer =
      <>
          <div className="Earned__information" style={earnedInformationStyle}>
            {/* Relative */}
            {titleDescReasonValidity}
            {couponNumberState}
            <div onClick={this.props.instructionsCallback} className="Instructions" style={linksStyle}>{instructionsState}</div>
            
          </div>

          {/* Absolute */}
          {emailInstructionsState}
          {inputEmailState}
          {correctEmailState}
          {stateButton}
          <div onClick={this.props.termsCallback} className="Terms" style={linksStyle}>{termsText}</div>
          <div onClick={this.props.helpCallback} className="Help" style={linksStyle}>{helpText}</div>
      </>

    let earnedTextContainer =
      <div className="Earned__section-info" style={earnedSectionInfoStyle}>
        {textContainer}
      </div>
    //-- END Reward Container

    let containerRadius = {};
    if (this.props.configuration !== undefined && this.props.configuration.z_cr !== undefined)
      containerRadius = { borderRadius: this.props.configuration.z_cr };
    let containerRadiusEmpty = {};
    let containerRadiusState;
    if (this.props.configuration !== undefined) {
      containerRadiusState = containerRadius
    } else {
      containerRadiusState = containerRadiusEmpty
    }

    let earnedStyle = earned
    earnedStyle.borderRadius = containerRadiusState.borderRadius

    //Badge
    let badgeEarned = <></>
    if(this.props.useBadge){
      if(this.state.mShowEarnedReward || this.state.mShowDataEnrichment)
        badgeEarned = <div className="Earned__badge-available" >{BADGE_TEXT_AVAILABLE}</div>
      else if(this.state.mShowClaimedReward) 
      badgeEarned = <div className="Earned__badge-redeemed" >{BADGE_TEXT_REDEEMED}</div>
    }
      

    return (
          <div className="Earned" style={earnedStyle} ref={this.ref}>
            {earnedHeaderStateContainer}
            {earnedTextContainer}
            {logoContainerState}
            {topActionsState}
            {badgeEarned}
          </div>
    );
  }
}

export default Earned;