import React from 'react';
import './styles/Share.css'
import { Spring } from 'react-spring/renderprops'

const SCREEN_MAX_WIDTH = 650

const HEIGHT_VERTICAL       = "480px"
const WIDTH_VERTICAL        = "680px"
const MIN_WIDTH_VERTICAL    = "640px"

const HEIGHT_HORIZONTAL     = "680px"
const WIDTH_HORIZONTAL      = "380px"
const MIN_WIDTH_HORIZONTAL  = "300px"

class Share extends React.PureComponent {

    state = {
        disableShareButton : true,
        ownName: false,
        otherName : false,
        otherEmail : false,
        nameText : "",
        otherNameText : "",
        otherEmailText : ""
    }

    constructor(props) {
        super(props)
    
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions()
    }

    updateDimensions = () => {
        if (this.ismounted) {
          this.setState({ state: this.state })
        }
    }

    componentDidMount() {
        this.ismounted = true
    }

    componentWillUnmount(){
        this.ismounted = false
    }

    emailIsValid (email) {
        return /\S+@\S+\.\S+/.test(email)
    }

    onOwnInfoName = (e) =>{
        let name = {value : e.target.value}
        if(name.value.length > 0){
            this.setState({
                disableShareButton : true,
                ownName: true,
                otherName : this.state.otherName,
                otherEmail : this.state.otherEmail,
                nameText : name.value,
                otherNameText : this.state.otherNameText,
                otherEmailText : this.state.otherEmailText
            })
            if(this.state.otherName === true && this.state.otherEmail === true ){
                this.setState({
                    disableShareButton : false,
                    ownName: true,
                    otherName : true,
                    otherEmail : true,
                    nameText : name.value,
                    otherNameText : this.state.otherNameText,
                    otherEmailText : this.state.otherEmailText

                })
            }
        } else {
            this.setState({
                disableShareButton : true,
                ownName: false,
                otherName : this.state.otherName,
                otherEmail : this.state.otherEmail,
                nameText : "",
                otherNameText : this.state.otherNameText,
                otherEmailText : this.state.otherEmailText
            })
        }
    }

    onOtherInfoName = (e) =>{
        let otherNameText = {value : e.target.value}
        if(otherNameText.value.length > 0){
            this.setState({
                disableShareButton : true,
                ownName: this.state.ownName,
                otherName : true,
                otherEmail : this.state.otherEmail,
                nameText : this.state.nameText,
                otherNameText : otherNameText.value,
                otherEmailText : this.state.otherEmailText
            })

            if(this.state.ownName === true && this.state.otherEmail === true ){
                this.setState({
                    disableShareButton : false,
                    ownName: true,
                    otherName : true,
                    otherEmail : true,
                    nameText : this.state.nameText,
                    otherNameText : otherNameText.value,
                    otherEmailText : this.state.otherEmailText
                })
            }
        } else {
            this.setState({
                disableShareButton : true,
                ownName: this.state.ownName,
                otherName : false,
                otherEmail : this.state.otherEmail,
                nameText : this.state.nameText,
                otherNameText : "",
                otherEmailText : this.state.otherEmailText
            })
        }
    }

    onOtherInfoMail = (e) =>{
        let otherEmail = {value : e.target.value}
        if(this.emailIsValid(otherEmail.value)){
            this.setState({
                disableShareButton : true,
                ownName: this.state.ownName,
                otherName : this.state.otherName,
                otherEmail : true,
                nameText : this.state.nameText,
                otherNameText : this.state.otherNameText,
                otherEmailText : otherEmail.value
            })

            if(this.state.ownName === true && this.state.otherName === true){
                this.setState({
                    disableShareButton : false,
                    ownName: true,
                    otherName : true,
                    otherEmail : true,
                    nameText : this.state.nameText,
                    otherNameText : this.state.otherNameText,
                    otherEmailText : otherEmail.value
                })
            }
        } else {
            this.setState({
                disableShareButton : true,
                ownName: this.state.ownName,
                otherName : this.state.otherName,
                otherEmail : false,
                nameText : this.state.nameText,
                otherNameText : this.state.otherNameText,
                otherEmailText : ""
            })
        }
    }

    onShareReward = (e) => {
        this.props.shareRewardCallback(
            this.state.nameText,
            this.state.otherNameText,
            this.state.otherEmailText)
    }

    render(){

        let doneStyle;
        let shareInformationContainer;
        if (window.innerWidth > SCREEN_MAX_WIDTH) {
            doneStyle = {
              minHeight: HEIGHT_VERTICAL,
              maxHeight: HEIGHT_VERTICAL,
              minWidth: MIN_WIDTH_VERTICAL,
              maxWidth: WIDTH_VERTICAL
            }
            shareInformationContainer = { marginTop : "30%" }
        } else {
            doneStyle = {
                height: HEIGHT_HORIZONTAL,
                minWidth: MIN_WIDTH_HORIZONTAL,
                maxWidth: WIDTH_HORIZONTAL
            }
            shareInformationContainer = { top : "50%" }
        }

        let selfNameInput =  <input className="input__share-self-name" onChange={this.onOwnInfoName} placeholder={this.props.namePlaceholder}></input>
        let selfNameInputCorrect = <input className="input__share-self-name-correct" onChange={this.onOwnInfoName} placeholder={this.props.namePlaceholder}></input>
        let correctNameImg = <img className="correct-share-self-name" src={this.props.correctImg} alt="doneNameImage"></img>
        let correctNameImgEmpty = <div></div>
        let correctNameImgState;
        let selfNameInputState;
            if(this.state.ownName){
                selfNameInputState = selfNameInputCorrect
                correctNameImgState = correctNameImg
            }
            else {
                selfNameInputState = selfNameInput
                correctNameImgState = correctNameImgEmpty
            }

        let otherNameInput =  <input className="input__share-name" onChange={this.onOtherInfoName} placeholder={this.props.otherNamePlaceholder}></input>
        let otherNameInputCorrect = <input className="input__share-name-correct" onChange={this.onOtherInfoName} placeholder={this.props.otherNamePlaceholder}></input>
        let correctOtherNameImg = <img className="correct-share-name" src={this.props.correctImg} alt="doneOtherNameImage"></img>
        let correctOtherNameImgEmpty = <div></div>
        let correctOtherNameImgState;
        let otherNameInputState;
            if(this.state.otherName){
                otherNameInputState = otherNameInputCorrect
                correctOtherNameImgState = correctOtherNameImg
            }
            else {
                otherNameInputState = otherNameInput
                correctOtherNameImgState = correctOtherNameImgEmpty
            }

        let otherEmailInput =  <input className="input__share-email" onChange={this.onOtherInfoMail} placeholder={this.props.otherEmailPlaceholder}></input>
        let otherEmailInputCorrect = <input className="input__share-email-correct" onChange={this.onOtherInfoMail} placeholder={this.props.otherEmailPlaceholder}></input>
        let correctOtherEmailImg = <img className="correct-share-mail" src={this.props.correctImg} alt="doneOtherEmailImage"></img>
        let correctOtherEmailImgEmpty = <div></div>
        let correctOtherEmailImgState;
        let otherEmailInputState;
            if(this.state.otherEmail){
                otherEmailInputState = otherEmailInputCorrect
                correctOtherEmailImgState = correctOtherEmailImg
            }
            else {
                otherEmailInputState = otherEmailInput
                correctOtherEmailImgState = correctOtherEmailImgEmpty
            }

        return (

            <Spring from={{ marginTop: -500 }} to={{ marginTop: 0 }}>
               {props => (<div style={props}>

                    <div className="Share" style={doneStyle}>
                        <div className="Share__container">
                            <img className="Share__image" alt="imageGift" src={this.props.giftIcon}></img>
                            <div className="Share__title">{this.props.title}</div>
                            <div className="Share__text">{this.props.text}</div>
                        </div>
                        <div className="Share__information-container" style = {shareInformationContainer}>
                            <div className="Share__text-own-info">{this.props.ownInfo}</div>
                            {selfNameInputState}
                            {correctNameImgState}
                            <div className="Share__text-other-info">{this.props.otherInfo}</div>
                            {otherNameInputState}
                            {correctOtherNameImgState}
                            {otherEmailInputState}
                            {correctOtherEmailImgState}
                        </div>
                        <button onClick={this.onShareReward} className="button__share" disabled={this.state.disableShareButton}>{this.props.buttonShare}</button>
                        <button onClick={this.props.onBack} className="button__share-cancel">{this.props.buttonCancel}</button>
                    </div>

                </div>)}
            </Spring> 
      );
    }
}

export default Share;