import React from 'react';
import './styles/ShareDone.css'
import { Spring } from 'react-spring/renderprops'

const SCREEN_MAX_WIDTH = 650

const HEIGHT_VERTICAL       = "480px"
const WIDTH_VERTICAL        = "680px"
const MIN_WIDTH_VERTICAL    = "640px"

const HEIGHT_HORIZONTAL     = "680px"
const WIDTH_HORIZONTAL      = "380px"
const MIN_WIDTH_HORIZONTAL  = "300px"

class ShareDone extends React.PureComponent {

    constructor(props) {
        super(props)
    
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions()
    }

    updateDimensions = () => {
        if (this.ismounted) {
          this.setState({ state: this.state })
        }
    }

    componentDidMount() {
        this.ismounted = true
    }

    componentWillUnmount(){
        this.ismounted = false
      }

    onContinue = (e) =>{
        try{
            if(this.props.isMobile){
                
                try { window.AndroidInterface.onMessage("On Continue Shared") } catch(ex){}
                try { window.webkit.messageHandlers.AppleInterface.postMessage("On Continue Shared")} catch(error) { }
                try { window.parent.postMessage("On Continue Shared", "*")} catch(error) { }
            } else {
                if(this.props.configuration.buttonClose !== undefined && 
                    this.props.configuration.buttonClose.web !== undefined && 
                    this.props.configuration.buttonClose.web.action !== undefined)
                    window.run(this.props.configuration.buttonClose.web.action)
            }

          } catch(error) { }
    }

    render(){

        let shareDoneStyle;
        if (window.innerWidth > SCREEN_MAX_WIDTH) {
            shareDoneStyle = {
              minHeight: HEIGHT_VERTICAL,
              maxHeight: HEIGHT_VERTICAL,
              minWidth: MIN_WIDTH_VERTICAL,
              maxWidth: WIDTH_VERTICAL
            }
        } else {
            shareDoneStyle = {
                height: HEIGHT_HORIZONTAL,
                minWidth: MIN_WIDTH_HORIZONTAL,
                maxWidth: WIDTH_HORIZONTAL
            }
        }

        return (

            <Spring from={{ marginTop: -500 }} to={{ marginTop: 0 }}>
               {props => (<div style={props}>
            
                    <div className="ShareDone" style={shareDoneStyle}>
                        {/*<img className="Background__shared-done" src={this.props.shareDoneImage} alt="shareDoneBackground"></img>*/}

                        <div className="ShareDone__container">
                            <img className="ShareDone__image" alt="imageHappy" src={this.props.happyIcon}></img>
                            <div className="ShareDone__title">{this.props.title}</div>
                            <div className="ShareDone__text">{this.props.text}</div>
                        </div>

                        <div className="ShareDone__information-container">
                            <div className="ShareDone__other-name">{this.props.shareOtherName}</div>
                            <div className="ShareDone__other-email">{this.props.shareOtherEmail}</div>
                        </div>

                        <button onClick={this.onContinue} className="button__continue">{this.props.continueText}</button>
                    </div>

                </div>)}
            </Spring> 
        )
    }
}

export default ShareDone;