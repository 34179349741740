import React from "react";
import { BrowserRouter, Route, Switch} from 'react-router-dom'
import MainPage from './pages/MainPage';

function App() {

 
  return (
    <BrowserRouter>
      <Switch>
          {/*<Route exact path="/list" component={List}/>*/}
          {/*<Route exact path="/List" component={List}/>*/}
          {/*<CacheRoute exact path="/List/:userid/:partnerid" component={List}/>*/}
          {/*<CacheRoute exact path="/list/:userid/:partnerid" component={List}/>*/}     
          <Route exact path="/:alias" component={MainPage}/>
          <Route exact path="/:alias/:field" component={MainPage}/>
          <Route exact path="/" component={MainPage}/>
        </Switch>
    </BrowserRouter>   
  );
}

export default App;
