import React from 'react';
import './styles/AuthGiveaways.css'
import { Spring } from 'react-spring/renderprops'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber  } from 'react-phone-number-input'
import { isStyledComponent } from 'styled-components';

const SCREEN_MAX_WIDTH = 650

const ONE_HUNDRED_PERCENT   = "100%"
const SEVENTY_FIVE_PERCENT  = "75%"
const SIXTY_FIVE_PERCENT    = "65%"
const FIFTY_PERCENT         = "50%"
const TWENTY_FIVE_PERCENT   = "25%"
const FIFTEEN_PERCENT       = "15%"

const ROW_NAME = "row"
const COLUMN_NAME = "column"

class AuthGiveaways extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
          mShowEnterFields: false,
          disableGenerateRewardButton: true,
          fieldLayoutButtonDisable : (this.props.fieldListValue.length === 0 ? true : false),
          fieldInputValueList : this.props.fieldListValue,
          dataEnrichValueList : this.props.dataEnrichListValue,
          alertState : false,
          termsAndPolicy : true,
          fieldInputValueCount : this.props.giveaway.fields.length,
          emailError : "",
          phoneError : ""
        }
        this.fieldInputValueList = this.props.fieldListValue
        this.dataEnrichValueList = this.props.dataEnrichListValue
        this.termsAndPolicyChecked = true;
        this.fieldValue = this.props.fieldValue

        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions()
    }

    updateDimensions = () => {
        if (this.ismounted) {
          this.setState({ state: this.state })
        }
    }

    componentDidMount() {
        this.ismounted = true

        if(this.fieldValue !== undefined){
            this.fieldInputValueList[0] = this.fieldValue;
            this.setState({ fieldInputValueList : this.fieldInputValueList})
            this.props.changedValue(this.fieldInputValueList)
            this.reviewButtonStateChange()
        }
    }

    componentWillUnmount(){
        this.ismounted = false
    }

    componentDidUpdate() {
        this.setState({ alertState: this.props.alertState });
    }

    isValidEmail = (email) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!email || regex.test(email) === false){
            this.setState({emailError: "Correo electrónico invalido"});
            return false;
        }else{
            this.setState({emailError: ""});
        }

        return true; 
    }

    isValidPhone = (phone) => {
        if(!isValidPhoneNumber(phone)){
            this.setState({ phoneError: "El número de teléfono no es valido" });
            return false;
        }else{
            this.setState({ phoneError: "" });
        }
        return true;
    }

    onDataEnrichOptionalTextChange = (e) => {
        let nameIndex = this.dataEnrichValueList.findIndex(x => x.name === e.target.name);
        if(nameIndex === -1){
            this.dataEnrichValueList.push({ name: e.target.name, value: e.target.value, required: false });
        }else{
            this.dataEnrichValueList[nameIndex] = {name: e.target.name, value: e.target.value, required: false};
        }

        this.setState({ dataEnrichValueList : this.dataEnrichValueList});
        this.props.changeDataEnrich(this.dataEnrichValueList);
    }

    onDataEnrichTextChange = (e) => { 
        let addValue = true;
        if(e.target.type === "email"){
            addValue = this.isValidEmail(e.target.value);
        }else if(e.target.type === "phone"){
            addValue = this.isValidPhone(e.target.value);
        }else{
            addValue = e.target.value ?? "";
        }
        
        let nameIndex = this.dataEnrichValueList.findIndex(x => x.name === e.target.name);
        if(addValue){
            if(nameIndex === -1){
                this.dataEnrichValueList.push({ name: e.target.name, value: e.target.value, required: true });
            }else{
                this.dataEnrichValueList[nameIndex] = {name: e.target.name, value: e.target.value, required: true};
            }
        }else{
            if(nameIndex > -1){
                this.dataEnrichValueList.splice(nameIndex, 1);
            }
        }

        this.setState({ dataEnrichValueList : this.dataEnrichValueList});
        this.props.changeDataEnrich(this.dataEnrichValueList);
        this.reviewButtonStateChange();
    }

    onFieldTextChange = (e) => { 
        let fieldValue = { value: e.target.value }

        this.fieldInputValueList[e.target.name] = fieldValue.value
        this.setState({ fieldInputValueList : this.fieldInputValueList})
        
        this.props.changedValue(this.fieldInputValueList)

        this.reviewButtonStateChange()
    }

    onClickFieldsLayout = (e) => {
        this.props.onClickFieldsLayout(this.fieldInputValueList, this.dataEnrichValueList)
    }

    onClickOk = (e) => {
    this.props.alertChangeState(false)
    this.setState({ alertState: false });
    }

    onAcceptTermsAndPolicy = (e) => {
        let changeValue = { value : e.target.checked }
        this.termsAndPolicyChecked = changeValue.value
        this.reviewButtonStateChange()

        this.setState({ termsAndPolicy :  this.termsAndPolicyChecked})
    }

    reviewButtonStateChange = () => {
        var isOneEmpty = false
        this.fieldInputValueList.forEach(element => {
            if(element.length === 0)
                isOneEmpty = true
        })

        let fieldsRequireds = this.props.giveaway.fields.length + this.props.giveaway.dataEnrich.filter(x => x.required === true).length;
        let valuesFill = this.fieldInputValueList.length + this.dataEnrichValueList.filter(x => x.required === true).length;

        if(!isOneEmpty > 0 && this.termsAndPolicyChecked && fieldsRequireds === valuesFill){
            this.setState({ fieldLayoutButtonDisable: false });
        }else{
            this.setState({ fieldLayoutButtonDisable: true });
        }

        // console.log(this.dataEnrichValueList);
    }



    extraInfoClick = (e, index) => {
        this.props.extraInfoCallback(index)
    }

    render(){

        let logoImage = undefined;
        if(this.props.giveaway !== undefined && this.props.giveaway.logoImage){
          logoImage = this.props.giveaway.logoImage;
        }
    
        let isVertical;
        let earned;
        let earnedHeaderStyle;
        let earnedSectionInfoStyle;

        let titleFontSize = 0;
        let subtitleFontSize = 0;

        if (window.innerWidth > SCREEN_MAX_WIDTH) {
      
            isVertical = false;
            earned = {
              maxHeight : "480px",
              minHeight : "300px",
              height : window.innerHeight * 0.8,
              
              minWidth : "650px", 
              maxWidth : "680px",
              width : window.innerWidth * 0.9,
      
              flexFlow: ROW_NAME
            }

            earnedSectionInfoStyle = { height: ONE_HUNDRED_PERCENT, minHeight: earned.minHeight, width: FIFTY_PERCENT }
            earnedHeaderStyle = { height: ONE_HUNDRED_PERCENT, maxHeight: earned.maxHeight, width: FIFTY_PERCENT }

            //Query Medias
                //Title font Size
                titleFontSize = window.innerWidth * 22 / 680;
                if(titleFontSize >= 22)
                    titleFontSize = 22;

                //Title font Size
                subtitleFontSize = window.innerWidth * 14 / 680;
                if(subtitleFontSize >= 14)
                    subtitleFontSize = 14;
            //End Query Medias
        } else {
            isVertical = true;
            earned = {
              maxHeight : "600px",
              minHeight : "470px",
              height : window.innerHeight * 0.8,
                    
              minWidth : "200px", 
              maxWidth : "450px",
              width : window.innerWidth * 0.9,
      
              flexFlow: COLUMN_NAME,
            }

            earnedSectionInfoStyle = { height: FIFTY_PERCENT, width: ONE_HUNDRED_PERCENT }
            earnedSectionInfoStyle = { height: FIFTY_PERCENT, width: ONE_HUNDRED_PERCENT }
            earnedHeaderStyle = { height: FIFTY_PERCENT, width: ONE_HUNDRED_PERCENT }

            //Query Medias
                //Title font Size
                titleFontSize = window.innerWidth * 22 / 450;
                if(titleFontSize >= 22)
                    titleFontSize = 22;
                
                //Title font Size
                subtitleFontSize = window.innerWidth * 16 / 450;
                if(subtitleFontSize >= 16)
                    subtitleFontSize = 16;
            //End Query Medias
        }

        let itemAlign = (align) => {
            if (isVertical) {
              switch (align) {
                case "right":
                  return { right: TWENTY_FIVE_PERCENT }
                case "left":
                  return { right: SEVENTY_FIVE_PERCENT }
                case "center":
                  return { right: FIFTY_PERCENT }
                default:
                  return { right: TWENTY_FIVE_PERCENT }
              }
            } else {
              switch (align) {
                case "right":
                  return { right: FIFTY_PERCENT, top : FIFTEEN_PERCENT }
                case "left":
                  return { right: FIFTY_PERCENT, top : SEVENTY_FIVE_PERCENT }
                case "center":
                  return { right: FIFTY_PERCENT, top : FIFTY_PERCENT }
                default:
                  return { right: FIFTY_PERCENT, top : FIFTY_PERCENT }
              }
            }
          }

        // -- Dynamic Styles Start
        let advertiserLogoAlign = {};
        if (this.props.configuration !== undefined && this.props.configuration.a_al_lgn !== undefined)
          advertiserLogoAlign = itemAlign(this.props.configuration.a_al_lgn)

        let partnerTitleStyle = {}
        if (this.props.configuration !== undefined && this.props.configuration.a_pt_clr !== undefined)
            partnerTitleStyle.color = this.props.configuration.a_pt_clr
        if(this.props.configuration !== undefined && this.props.configuration.a_pt_wght !== undefined)
            partnerTitleStyle.fontWeight = this.props.configuration.a_pt_wght
        partnerTitleStyle.fontSize = `${subtitleFontSize}px`;

        let partnerRewardStyle = {}
        if (this.props.configuration !== undefined && this.props.configuration.a_pr_clr !== undefined)
            partnerRewardStyle.color = this.props.configuration.a_pr_clr
        if (this.props.configuration !== undefined && this.props.configuration.a_pr_lgn !== undefined)
            partnerRewardStyle.textAlign = this.props.configuration.a_pr_lgn
        if(this.props.configuration !== undefined && this.props.configuration.a_pr_wght !== undefined)
            partnerTitleStyle.fontWeight = this.props.configuration.a_pr_wght
        partnerRewardStyle.fontSize = `${titleFontSize}px`;


        let actionButtonStyle = {}
        let actionButtonStyleDisable = {}
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_rds !== undefined) {
            actionButtonStyle.borderRadius = this.props.configuration.z_ab_rds
            actionButtonStyleDisable.borderRadius = this.props.configuration.z_ab_rds 
        }
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_clr !== undefined) {
            actionButtonStyle.background = this.props.configuration.z_ab_clr
            actionButtonStyleDisable.background = this.props.configuration.z_ab_clr
        }
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_txtClr !== undefined) {
            actionButtonStyle.color = this.props.configuration.z_ab_txtClr
            actionButtonStyleDisable.color = this.props.configuration.z_ab_txtClr
        }
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_txtWght !== undefined) {
            actionButtonStyle.fontWeight = this.props.configuration.z_ab_txtWght
            actionButtonStyleDisable.fontWeight = this.props.configuration.z_ab_txtWght
        }

        let detailsTextStyle = {}
        if (this.props.configuration !== undefined && this.props.configuration.c_dt_clr !== undefined){
            detailsTextStyle.color = this.props.configuration.c_dt_clr
        }
        if (this.props.configuration !== undefined && this.props.configuration.c_dt_wght !== undefined){
        detailsTextStyle.fontWeight= this.props.configuration.c_dt_wght
        }
        
        let inputFormStyle = {}
        let inputFormStyleCorrect = {}
        if (this.props.configuration !== undefined && this.props.configuration.actionButton_radius !== undefined) {
          inputFormStyle = { borderRadius: this.props.configuration.actionButton_radius }
          inputFormStyleCorrect = { borderRadius: this.props.configuration.actionButton_radius }
        }
        if (this.props.configuration !== undefined && this.props.configuration.inputForm_border !== undefined) {
          inputFormStyle.borderWidth = this.props.configuration.inputForm_border
          inputFormStyleCorrect.borderWidth = this.props.configuration.inputForm_border
        }
        let advertiserLogoAlignEmpty = {};
        let advertiserLogoAlignState;
        if (this.props.configuration !== undefined) {
            advertiserLogoAlignState = advertiserLogoAlign
        } else {
            advertiserLogoAlignState = advertiserLogoAlignEmpty
        }

        let headerContainerColor = {};
        if (this.props.configuration !== undefined && this.props.configuration.a_cr !== undefined)
            headerContainerColor = { background: this.props.configuration.a_cr };

        let instructionsTextStyle = {}
        if (this.props.configuration !== undefined && this.props.configuration.z_ai_lgn !== undefined)
            instructionsTextStyle.textAlign = this.props.configuration.z_ai_lgn
        if (this.props.configuration !== undefined && this.props.configuration.z_ai_clr !== undefined)
            instructionsTextStyle.color = this.props.configuration.z_ai_clr
        if (this.props.configuration !== undefined && this.props.configuration.z_ai_wght !== undefined)
            instructionsTextStyle.fontWeight =  this.props.configuration.z_ai_wght

        // -- Dynamic Styles End

        let earnedHeaderInputFieldContainer =
            <div className="Giveaways__header-input-fields" style={{...earnedHeaderStyle, background : headerContainerColor.background}} >
                
                <div className="Circle__image-container-giveaway" style={{...advertiserLogoAlignState, backgroundImage:`url(${logoImage})`}}></div>
                <div className="Giveaways__partner" style={partnerTitleStyle}>{this.props.giveaway.content.thanks_title}</div>
                <div className="Giveaways__title" style={partnerRewardStyle}>{this.props.giveaway.content.main_title}</div>
                <div className="Giveaways__partner" style={partnerTitleStyle}>{this.props.giveaway.content.sub_title}</div>
            </div> 
            
        let earnedHeaderStateContainer = earnedHeaderInputFieldContainer;
        
        //-- Input Field view
        let actionButtonInputFieldsStyle = {...actionButtonStyle}
        actionButtonInputFieldsStyle.background = this.state.fieldLayoutButtonDisable ? "#969696" : actionButtonStyle.background
        let inputFieldsContainer = 
            <>
                <div className="Giveaways__Input-Instructions" style={instructionsTextStyle}>{this.props.giveaway.content.instructions}</div>
                { this.props.giveaway.fields.map((item, index) => {
                        if(this.fieldValue == undefined)
                        {
                            return <React.Fragment key = {index}>
                                <input className="Giveaways__input-field" name={index} onChange={this.onFieldTextChange} placeholder={item.name} type="text" style={inputFormStyleCorrect} ></input>
                                <div className="Giveaways__detail-help" style={detailsTextStyle} onClick={ ((e) => this.extraInfoClick(e, index)) }>{item.help_title}</div>
                            </React.Fragment>
                        }else{
                            return <React.Fragment key = {index}>
                                <input className="Giveaways__input-field" name={index} onChange={this.onFieldTextChange} placeholder={item.name} type="text" style={inputFormStyleCorrect} value={ this.fieldValue } readOnly={true}></input>
                                <div className="Giveaways__detail-help" style={detailsTextStyle} onClick={ ((e) => this.extraInfoClick(e, index)) }>{item.help_title}</div>
                            </React.Fragment>
                        }
                    })   
                }

                {
                    //Agregamos los datos adicionales, configurados en el dataEnrich
                    this.props.giveaway.dataEnrich.map((item, index) => {
                        if(item.type === "phone"){
                            return <>
                                <PhoneInput key = {"data_enrich_" + index} className="Giveaways__input-field" name={item.name} defaultCountry="MX" placeholder={item.required ? item.label + " (requerido)" : item.label }
                                onChange={item.required ?? false ? (value) => this.onDataEnrichTextChange({ target:{ type: item.type, value: value ? value : "", name: item.name } }) : (value) => this.onDataEnrichOptionalTextChange({ target:{ type: item.type, value: value ? value : "", name: item.name } }) } />
                                <span className="Giveaways__detail-help text-danger">{ this.state.phoneError }</span>
                            </>
                        }else {
                            return <React.Fragment key = {"data_enrich_" + index}>
                                <input className="Giveaways__input-field" name={item.name} onChange={item.required ?? false ? this.onDataEnrichTextChange : this.onDataEnrichOptionalTextChange } required={item.required} placeholder={item.required ? item.label + " (requerido)" : item.label } type={item.type} style={inputFormStyleCorrect}></input>
                                <span className="Giveaways__detail-help text-danger">{ item.type === "email" ? this.state.emailError : ""}</span>
                            </React.Fragment>
                        }
                    })
                }

                <div className="Giveaways__fields-space"/>

                <button onClick={this.onClickFieldsLayout} disabled={this.state.fieldLayoutButtonDisable} className="button__builder-field" style={actionButtonInputFieldsStyle}>
                {this.props.giveaway !== undefined && this.props.giveaway.content !== undefined && this.props.giveaway.content.button_txt !== undefined  
                    ? this.props.giveaway.content.button_txt : this.props.buttonClaim}
                </button>

                <div className="Giveaways__Accept-terms-container">
                <input className="Giveaways__Accept-terms-checkbox" type="checkbox" checked = {this.state.termsAndPolicy} onChange={this.onAcceptTermsAndPolicy}/>
                <div className="Giveaways__detail-title" style={detailsTextStyle}>
                    <span className="Giveaways__normal-text">Acepto los </span>
                    <span className="Giveaways__strong-text" onClick={this.props.termsCallback}>Términos y condiciones</span> 
                    <span className="Giveaways__normal-text">del servicio de Bonnus y he leído las </span>
                    <span className="Giveaways__strong-text" onClick={this.props.helpCallback} >Políticas de privacidad.</span>
                </div>
                </div>
            </>

        //-- End Input Fields

        let earnedTextContainer =
        <div className="Giveaways__section-info" style={{...earnedSectionInfoStyle, background : headerContainerColor.background}}>
            {inputFieldsContainer}
        </div>

        let containerRadius = {};
        if (this.props.configuration !== undefined && this.props.configuration.z_cr !== undefined)
            containerRadius = { borderRadius: this.props.configuration.z_cr };

        let containerRadiusEmpty = {};
        let containerRadiusState;
        if (this.props.configuration !== undefined) {
            containerRadiusState = containerRadius
        } else {
            containerRadiusState = containerRadiusEmpty
        }

        let earnedStyle = earned
        earnedStyle.borderRadius = containerRadiusState.borderRadius

        let alertState = {}
        let alertEmpty = <></>
        let alertShow = 
        <div className="Giveaways__alert-background">
        <div className="Giveaways__alert-container">
            <div className="Giveaways__alert-text">{this.props.alertMessage}</div>
            <button className="button__builder-field" style={actionButtonStyle} onClick={this.onClickOk}> {this.props.alertOkText} </button>
        </div>
        </div>

        if(this.state.alertState === true)
            alertState = alertShow
        else
            alertState = alertEmpty

        return (

            <Spring from={{marginTop: -500 }} to={{marginTop: 0 }}>        
            {props => (<div style={props}>

                <div className="Giveaways" style={earnedStyle}>
                    {earnedHeaderStateContainer}
                    {earnedTextContainer}
                    {alertState}
                </div>

            </div>)}
            </Spring>
        );
    }

}

export default AuthGiveaways;