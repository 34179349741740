import React from 'react';
import './styles/Leaving.css'
import { Spring } from 'react-spring/renderprops'

const SCREEN_MAX_WIDTH = 650
class Leaving extends React.PureComponent {

    constructor(props) {
        super(props)
    
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions()
    }

    updateDimensions = () => {
        if (this.ismounted) {
          this.setState({ state: this.state })
        }
    }

    componentDidMount() {
        this.ismounted = true
    }

    render(){

        let leavingStyle;
        let leaveTitleStyle = {}
        let leaveTextStyle = {}
        let actionButtonStyle = {}
        let leaveButtonStyle = {}
        let buttonStay = this.props.buttonStay;
        let buttonLeave = this.props.buttonLeave;
        if (window.innerWidth > SCREEN_MAX_WIDTH) {
            leavingStyle = {
                maxHeight : "480px",
                minHeight : "300px",
                height : window.innerHeight * 0.8,
                
                minWidth : "650px", 
                maxWidth : "680px",
                width : window.innerWidth * 0.9,
            }
        } else {
            leavingStyle = {
                maxHeight : "600px",
                minHeight : "470px",
                height : window.innerHeight * 0.8,
                      
                minWidth : "200px", 
                maxWidth : "450px",
                width : window.innerWidth * 0.9,
            }
        }

        if(this.props.configuration !== undefined && this.props.configuration.z_cr !== undefined)
            leavingStyle.borderRadius = this.props.configuration.z_cr
        if(this.props.configuration !== undefined && this.props.configuration.d_lc_clr !== undefined)
            leavingStyle.background = this.props.configuration.d_lc_clr

        if (this.props.configuration !== undefined && this.props.configuration.z_ab_rds !== undefined)
            actionButtonStyle.borderRadius = this.props.configuration.z_ab_rds 
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_txtClr !== undefined)
            actionButtonStyle.color = this.props.configuration.z_ab_txtClr
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_brdrCrl !== undefined)
            actionButtonStyle.borderColor = this.props.configuration.z_ab_brdrCrl
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_clr !== undefined)
            actionButtonStyle.background = this.props.configuration.z_ab_clr
        if (this.props.configuration !== undefined && this.props.configuration.z_ab_ppr !== undefined)
            if(this.props.configuration.z_sb_ppr === "true"){
                buttonStay = buttonStay.toUpperCase();
            }

        if (this.props.configuration !== undefined && this.props.configuration.z_sb_rds !== undefined)
            leaveButtonStyle.borderRadius = this.props.configuration.z_sb_rds 
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_txtClr !== undefined)
            leaveButtonStyle.color = this.props.configuration.z_sb_txtClr
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_brdrCrl !== undefined)
            leaveButtonStyle.borderColor = this.props.configuration.z_sb_brdrCrl
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_clr !== undefined)
            leaveButtonStyle.background = this.props.configuration.z_sb_clr
        if (this.props.configuration !== undefined && this.props.configuration.z_sb_ppr !== undefined)
            if(this.props.configuration.z_ab_ppr === "true"){
                buttonLeave = buttonLeave.toUpperCase();
            }

        if (this.props.configuration !== undefined && this.props.configuration.z_tn_clr !== undefined)
            leaveTitleStyle.color = this.props.configuration.z_tn_clr
        if (this.props.configuration !== undefined && this.props.configuration.d_ltx_wght !== undefined)
            leaveTitleStyle.fontWeight = this.props.configuration.z_tn_wght
        if (this.props.configuration !== undefined && this.props.configuration.z_tn_lgn !== undefined)
            leaveTitleStyle.textAlign = this.props.configuration.z_tn_lgn

        if (this.props.configuration !== undefined && this.props.configuration.g_tq_clr !== undefined)
            leaveTextStyle.color = this.props.configuration.g_tq_clr
        if (this.props.configuration !== undefined && this.props.configuration.g_tq_wght !== undefined)
            leaveTextStyle.fontWeight = this.props.configuration.g_tq_wght
        if (this.props.configuration !== undefined && this.props.configuration.g_tq_lgn !== undefined)
            leaveTextStyle.textAlign = this.props.configuration.g_tq_lgn

        if (this.props.configuration !== undefined && this.props.configuration.actionButton_radius !== undefined)
            leaveButtonStyle.borderRadius = this.props.configuration.actionButton_radius

        return (
            <Spring from={{ marginTop: -500 }} to={{ marginTop: 0 }}>
               {props => (<div style={props}>

                    <div className="Leaving" style={leavingStyle}>
                        <button onClick={this.props.onBack} className="button__stay" style={actionButtonStyle}>{buttonStay}</button>
                        <button onClick={this.props.onLeave} className="button__leave" style={leaveButtonStyle}>{buttonLeave}</button>
                        <div className="Leave__container">
                            <img className="Leave__image" alt="imageSad" src={this.props.sadIcon}></img>
                            <div className="Leave__title" style={leaveTitleStyle}>{this.props.title}</div>
                            <div className="Leave__text" style={leaveTextStyle}>{this.props.text}</div>
                        </div>
                    </div>

                </div>)}
            </Spring> 
      );
    }
}

export default Leaving;